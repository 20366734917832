import './style.css'

import React from 'react'

export default function Email(){
    return (
        <div className="fixed fixed-left">
            <a href="mailto:marianasfernandessousa@gmail.com" target="_blank">
                marianasfernandessousa@gmail.com
            </a>
        </div>
    )
}